<template>
  <section class="patient-info flex-column--align-center">
    <div class="patient-info__hint main-text otherXS">
      {{ $t('dashboard.label.enterDetailsToProceed') }}
    </div>
    <div class="patient-info__name flex full-width">
      <input-with-label
        :value="value.firstName"
        class="patient-info__name__first-name mr5"
        name="first-name"
        :label="$t('dashboard.formulation.firstName')"
        required
        @input="updateFirstName"
      />
      <input-with-label
        :value="value.lastName"
        name="last-name"
        class="patient-info__name__last-name ml5"
        :label="$t('dashboard.formulation.lastName')"
        @input="updateLastName"
      />
    </div>
    <input-with-label
      :value="value.email"
      type="email"
      name="email"
      :label="$t('dashboard.label.emailAddress')"
      required
      @input="updateEmail"
    />
    <phone-field-with-constraint
      class="min-info-phone-field full-width"
      :iso-code="value.phoneIsoCode"
      :phone-number="value.phone"
      :constraint="value.smsAccepted"
      :constraint-text="$t('dashboard.consent.patientDetails.agreeToReceiveNotifications')"
      :constraint-hint="$t('dashboard.consent.patientDetailsHint.agreeToReceiveNotifications')"
      :is-constraint-disabled="!value.phone"
      @update-iso-code="updateIsoCode"
      @update-phone-number="updatePhoneNumber"
      @update-constraint="updateSmsAccepted"
    />
  </section>
</template>

<script>
import InputWithLabel from '@/modules/dashboard/components/common/InputWithLabel';
import PhoneFieldWithConstraint from '@/modules/dashboard/components/dashboard/common/PhoneFieldWithConstraint';

import { PATIENT_MIN_INFO_DEFAULT_VALUES } from '@/modules/dashboard/api/constants';

export default {
  name: 'PatientMinInfoForm',
  components: { PhoneFieldWithConstraint, InputWithLabel },
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      default: () => ({
        ...PATIENT_MIN_INFO_DEFAULT_VALUES
      })
    }
  },
  methods: {
    updateFirstName(firstName) {
      this.$emit('input', { ...this.value, firstName });
    },
    updateLastName(lastName) {
      this.$emit('input', { ...this.value, lastName });
    },
    updateEmail(email) {
      this.$emit('input', { ...this.value, email });
    },
    updateIsoCode(phoneIsoCode) {
      this.$emit('input', { ...this.value, phoneIsoCode });
    },
    updatePhoneNumber(phone) {
      this.$emit('input', { ...this.value, phone });
    },
    updateSmsAccepted(smsAccepted) {
      this.$emit('input', { ...this.value, smsAccepted });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.patient-info {
  width: 420px;

  &__hint {
    color: $grey-color;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .min-info-phone-field /deep/ .phone-number__container {
    max-width: 205px;
  }
}

@media (max-width: 767px) {
  .patient-info {
    width: 100%;

    &__name {
      flex-direction: column;

      &__first-name,
      &__last-name {
        margin: 0;
      }
    }
  }
}
</style>
