<template>
  <modal
    name="code-verification-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="modal__content-container">
          <div class="flex-column flex--center--center text-align-center modal__text-container">
            <span class="mb30 pre-line" :class="{ 'text-error': !isEnteredCodeValid }">
              {{ modalDescription }}
            </span>
            <span class="mb15">{{ $t('dashboard.label.verificationCode') }}</span>
            <input
              ref="input"
              v-allow-only-reg-exp="/^([0-9])$/"
              maxlength="12"
              :value="formattedVerificationCode"
              class="form-control verification-code__input"
              @input="onVerificationCodeInput"
            />
          </div>
        </div>
        <div class="flex-column flex--center--center">
          <div
            class=" rounded-button-orange rounded-button-orange--filled mb20"
            @click="onActionButtonClick"
          >
            {{ actionButtonLabel }}
          </div>
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.back') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { get } from 'lodash';

import { deleteSpaces, transformVerificationCode } from '@/modules/dashboard/utils';

export default {
  name: 'CodeVerificationModal',
  props: {
    verificationDescription: {
      type: String,
      required: true
    },
    isEnteredCodeValid: {
      type: Boolean,
      required: true
    },
    actionButtonName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      verificationCode: ''
    };
  },
  computed: {
    actionButtonLabel() {
      return this.actionButtonName || this.$t('dashboard.action.continue');
    },
    formattedVerificationCode() {
      return transformVerificationCode(this.verificationCode);
    },
    modalDescription() {
      return this.isEnteredCodeValid
        ? this.verificationDescription
        : this.$t('dashboard.label.verificationCodeError');
    }
  },
  methods: {
    onVerificationCodeInput(event) {
      this.verificationCode = get(event, ['target', 'value']);
    },
    onActionButtonClick() {
      this.$emit('action-button-click', deleteSpaces(this.verificationCode));
    },
    cancel() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/text';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/inputs';

.modal {
  &-window-content {
    padding: 30px;
  }

  &__content-container {
    margin-bottom: 25px;
  }

  &__text-container {
    margin-bottom: 20px;

    font-size: 13px;
    line-height: 150%;
  }

  &__text-link {
    color: $links-color;
    font-weight: 600;
  }
}

.text-error {
  color: $error-color;
}

.verification-code {
  &__input {
    width: 110px;
    height: auto;
    padding: 10px;

    border: 1px solid $light-blue;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    color: $main-title-color;
  }
}
</style>
