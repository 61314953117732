<template>
  <div class="patient-details">
    <modal-header-with-previous
      class="modal-header-with-previous"
      current-modal-name="new-treatment-patient-details-modal"
      previous-modal-name="new-treatment-modal"
    >
      <span slot="header-title" class="modal-header-with-previous__title">
        {{ $t('dashboard.label.patientDetails') }}
      </span>
    </modal-header-with-previous>
    <main class="patient-details__info flex-column--align-center">
      <patient-min-info-form v-model="patient" />
    </main>
    <div class="patient-details__constraint flex justify-content--center">
      <patient-terms-and-privacy-constraint v-model="patientPrivacyConstraint" required />
    </div>
    <div class="divider otherXS"></div>
    <footer class="patient-details__footer details-footer flex--center--center">
      <button
        class="details-footer__button rounded-button-white main-text-font-size"
        @click="onActionButtonClick"
      >
        {{ actionButtonText }}
      </button>
    </footer>

    <email-associated-error-modal
      is-quick-treatment-creation
      :is-recommended-treatment-creation="isRecommendedTreatmentCreation"
      @patient-name-click="redirectToPatientCard"
      @continue-click="onContinueClick"
    />
    <send-code-modal
      :additional-text="sendCodeModalHint"
      @send-code-click="onSendCodeClick"
      @close="onSendCodeModalCloseClick"
    >
      <template #main-text>
        <i18n path="dashboard.label.sendCodeText" tag="span" class="pre-wrap mb30">
          <span class="bold">{{ patientsDoctorName }}</span>
        </i18n>
      </template>
    </send-code-modal>
    <code-verification-modal
      :verification-description="codeVerificationMainText"
      :is-entered-code-valid="isEnteredCodeValid"
      :action-button-name="transferDataButtonLabel"
      @action-button-click="onTransferDataClick"
      @close="onCodeVerificationModalClose"
    />
    <code-verification-success-modal
      :main-text="$t('dashboard.label.medicalDataTransferred')"
      @ok-click="onOkCodeSuccessVerificationModalClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { get } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import PatientMinInfoForm from '@/modules/dashboard/components/dashboard/common/PatientMinInfoForm';
import EmailAssociatedErrorModal from '@/modules/dashboard/components/common/EmailAssociatedErrorModal';
import CodeVerificationModal from '@/modules/dashboard/components/common/code-verification-modal/CodeVerificationModal';
import SendCodeModal from '@/modules/dashboard/components/common/code-verification-modal/SendCodeModal';
import CodeVerificationSuccessModal from '@/modules/dashboard/components/common/code-verification-modal/CodeVerificationSuccessModal';
import PatientTermsAndPrivacyConstraint from '@/modules/dashboard/components/dashboard/common/PatientTermsAndPrivacyConstraint';

import { sendPatientTransferVerificationCode, verifyPatientTransfer } from '@/api';

import { handleRoutingInfo, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  NEW_TREATMENT_FLOW_TYPES,
  PATIENT_MIN_INFO_DEFAULT_VALUES,
  PHOTO_ANALYSIS_WITH_PREVIOUS_ROUTING_INFO,
  TREATMENT_SELECTION_ROUTING_INFO,
  MEDICAL_DATA_TRANSFER_SOURCE
} from '@/modules/dashboard/api/constants';
import { EMAIL_ERRORS } from '@/constants';

const TREATMENT_RECOMMENDATION_FLOW_NAMES = [
  NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_PHOTO_ANALYSIS,
  NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_TREATMENT_DIRECTORY
];

export default {
  name: 'NewTreatmentPatientDetails',
  components: {
    CodeVerificationSuccessModal,
    PatientTermsAndPrivacyConstraint,
    EmailAssociatedErrorModal,
    SendCodeModal,
    CodeVerificationModal,
    PatientMinInfoForm,
    ModalHeaderWithPrevious
  },
  props: {
    newTreatmentFlowType: {
      type: String,
      default: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_SCRATCH
    }
  },
  data() {
    return {
      patient: {
        ...PATIENT_MIN_INFO_DEFAULT_VALUES
      },
      patientPrivacyConstraint: false,
      isEnteredCodeValid: true
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      hasErrors: dashTypes.getters.HAS_ERRORS,
      emailError: dashTypes.getters.PATIENT_INFO_EMAIL_ERROR,
      hasPatientInfoEmailError: dashTypes.getters.HAS_PATIENT_INFO_EMAIL_ERROR,
      doctorCountry: rootTypes.getters.GET_USER_COUNTRY,
      currentDoctorId: rootTypes.getters.GET_USER_ID
    }),
    errorPatientId() {
      return get(this.emailError, 'data.id', null);
    },
    isRecommendedTreatmentCreation() {
      return TREATMENT_RECOMMENDATION_FLOW_NAMES.includes(this.newTreatmentFlowType);
    },
    sendCodeModalHint() {
      const email = get(this.currentPatient, 'email', '');

      return this.$t('dashboard.hint.sendCode', [email]);
    },
    patientsDoctorName() {
      return get(this.currentPatient, 'doctorName', '');
    },
    codeVerificationMainText() {
      const email = get(this.currentPatient, 'email', '');

      return this.$t('dashboard.label.verificationCodeText', [email]);
    },
    transferDataButtonLabel() {
      return this.$t('dashboard.button.transferData');
    },
    actionButtonText() {
      return this.newTreatmentFlowType === NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_SCRATCH
        ? this.$t('dashboard.button.goToTreatmentCreation')
        : this.$t('dashboard.action.continue');
    }
  },
  created() {
    this.patient.phoneIsoCode = this.doctorCountry;
    this.resetOpenedPatient();
  },
  methods: {
    ...mapMutations({
      resetCurrentPatient: dashTypes.mutations.RESET_CURRENT_PATIENT
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      createPatient: dashTypes.actions.CREATE_PATIENT,
      setCurrentPatient: dashTypes.actions.SET_CURRENT_PATIENT,
      resetOpenedPatient: dashTypes.actions.RESET_OPENED_PATIENT,
      getPatientById: dashTypes.actions.LOAD_PATIENT
    }),
    redirectToPatientCard() {
      if (!this.errorPatientId) {
        return;
      }

      this.resetCurrentPatient();

      this.$router.push({
        name: 'PatientCard',
        params: {
          id: this.errorPatientId
        }
      });
    },
    onSendCodeClick() {
      sendPatientTransferVerificationCode({
        patientId: this.currentPatientId,
        doctorId: this.currentDoctorId
      });
      this.$modal.show('code-verification-modal');
    },
    onSendCodeModalCloseClick() {
      this.resetCurrentPatient();
    },
    async onTransferDataClick(verificationCode) {
      if (!verificationCode) {
        this.isEnteredCodeValid = false;

        return;
      }

      this.setLoading(true);

      const { data } = await verifyPatientTransfer({
        patientId: this.currentPatientId,
        doctorId: this.currentDoctorId,
        verificationCode,
        medicalDataTransferSource: MEDICAL_DATA_TRANSFER_SOURCE.DASHBOARD
      });

      this.setLoading(false);

      if (!data.success) {
        this.isEnteredCodeValid = false;

        return;
      }

      this.isEnteredCodeValid = true;
      this.$modal.hide('code-verification-modal');
      this.$modal.show('code-verification-success-modal');
    },
    onCodeVerificationModalClose() {
      this.$modal.hide('code-verification-modal');
    },
    onOkCodeSuccessVerificationModalClick() {
      this.getPatientById(this.currentPatientId);
      this.$modal.hide('code-verification-success-modal');
      this.handleProceedTreatmentCreationFlow();
    },
    onContinueClick(patient) {
      switch (this.newTreatmentFlowType) {
        case NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_PHOTO_ANALYSIS: {
          this.setCurrentPatient(patient);
          this.goToAddPhotoAnalysisRouting();

          break;
        }
        case NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_TREATMENT_DIRECTORY: {
          this.setCurrentPatient(patient);
          this.goToTreatmentSelectionRouting();

          break;
        }
        default: {
          this.redirectToPatientCard();
        }
      }
    },
    goToAddPhotoAnalysisRouting() {
      this.closeCurrentModal();
      handleRoutingInfo(PHOTO_ANALYSIS_WITH_PREVIOUS_ROUTING_INFO, this.$router, this.$modal);
    },
    goToTreatmentSelectionRouting() {
      this.closeCurrentModal();
      handleRoutingInfo(TREATMENT_SELECTION_ROUTING_INFO, this.$router, this.$modal);
    },
    closeCurrentModal() {
      this.$modal.hide('new-treatment-patient-details-modal');
    },
    handleProceedTreatmentCreationFlow() {
      switch (this.newTreatmentFlowType) {
        case NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_PHOTO_ANALYSIS: {
          this.goToAddPhotoAnalysisRouting();

          break;
        }
        case NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_TREATMENT_DIRECTORY: {
          this.goToTreatmentSelectionRouting();

          break;
        }
        default: {
          const id = this.currentPatientId;
          const routeName = isMobileDevice() ? 'CreateTreatment' : 'PatientCard';

          this.resetCurrentPatient();

          this.$router.push({
            name: routeName,
            params: { id }
          });
        }
      }
    },
    async onActionButtonClick() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      await this.createPatientFromInfo();

      if (this.hasPatientInfoEmailError || this.hasErrors) {
        return;
      }

      if (this.currentPatient.warning === EMAIL_ERRORS.EMAIL_USED_BY_ANOTHER_DOCTOR_PATIENT) {
        this.$modal.show('send-code-modal');

        return;
      }

      this.handleProceedTreatmentCreationFlow();
    },
    async createPatientFromInfo() {
      const { firstName, lastName, email, phoneIsoCode, phone, smsAccepted } = this.patient;

      this.setLoading(true);
      await this.createPatient({
        firstName,
        lastName: lastName || null,
        email,
        phoneIsoCode: phoneIsoCode || this.doctorPhoneIsoCode,
        phone,
        smsAccepted
      });
      this.setLoading(false);

      if (this.hasPatientInfoEmailError) {
        this.$modal.show('email-associated-error-modal');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';

.patient-details {
  color: $main-title-color;

  &__info {
    margin: 119px 0 114px 0;
  }

  &__footer {
    height: 70px;
  }

  &__constraint {
    margin-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .patient-details {
    min-height: 100vh;
    background-color: $white;

    &__info {
      padding: 0 20px 20px 20px;
      margin: 0;
    }

    &__footer {
      height: auto;
    }

    &__constraint {
      padding: 0 20px;
      margin-bottom: 30px;
    }
  }

  .modal-header-with-previous {
    &__title {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .details-footer {
    &__button {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
