<template>
  <modal
    name="send-code-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal__content">
        <div class="modal__content-container">
          <div class="flex-column flex--center--center text-align-center modal__text-container">
            <slot name="main-text">
              <span class="mb30 pre-line">{{ mainText }}</span>
            </slot>
            <button class="rounded-button-white mb20" @click="onSendCodeClick">
              {{ $t('dashboard.button.sendCode') }}
            </button>
            <span v-if="additionalText" class="mb20 additional-text">{{ additionalText }}</span>
          </div>
        </div>
        <div class="flex-column flex--center--center">
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.back') }}
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SendCodeModal',
  props: {
    mainText: {
      type: String,
      default: ''
    },
    additionalText: {
      type: String,
      default: ''
    }
  },
  methods: {
    onSendCodeClick() {
      this.$emit('send-code-click');
      this.$modal.hide('send-code-modal');
    },
    cancel() {
      this.$emit('close');
      this.$modal.hide('send-code-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/text';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.modal {
  &__content {
    padding: 30px;
  }

  &__content-container {
    margin-bottom: 25px;
  }

  &__text-container {
    margin-bottom: 20px;

    font-size: 13px;
    line-height: 150%;

    color: $active-title-color;
  }

  &__text-link {
    color: $links-color;
    font-weight: 600;
  }
}

.additional-text {
  font-size: 12px;
  line-height: 14px;
  font-style: italic;

  color: $grey-color;
}

.mail-text {
  font-weight: bold;
}
</style>
