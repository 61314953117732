<template>
  <dashboard-constraint
    id="patientConstraint"
    class="patient-constraint"
    :value="value"
    name="patientConstraint"
    :required="required"
    @input="updateConstraint"
  >
    <template slot="constraint">
      <span
        class="patient-constraint__text patient-constraint-text constraint-text align-self--center"
        :class="{ error: hasError }"
      >
        <i18n path="dashboard.consent.patientDetails.agreeOnUniverskinTerms" tag="span">
          <span>
            <router-link
              class="patient-constraint-text__link"
              :to="{ name: 'Terms' }"
              title="Terms & Conditions"
              target="_blank"
            >
              Terms & Conditions
            </router-link>
          </span>
          <span>
            <router-link
              class="patient-constraint-text__link"
              :to="{ name: 'PatientNotice' }"
              title="Privacy Notice: Patients"
              target="_blank"
            >
              Patient Information
            </router-link>
          </span>
        </i18n>
      </span>
      <span
        v-tooltip="{
          content: $t('dashboard.consent.patientDetailsHint.agreeOnUniverskinTerms'),
          placement: 'left-start',
          trigger: tooltipTrigger
        }"
        class="custom-icon icon-info inline-block align-self--center"
      ></span>
    </template>
  </dashboard-constraint>
</template>

<script>
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'PatientTermsAndPrivacyConstraint',
  components: { DashboardConstraint },
  inject: ['$validator'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasError() {
      return this.errors.has('patientConstraint');
    },
    tooltipTrigger() {
      return isMobileDevice() ? 'click' : 'hover';
    }
  },
  methods: {
    updateConstraint(constraint) {
      this.$emit('input', constraint);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/text';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons.css';

.patient-constraint-text {
  &__link {
    text-decoration: underline;
    color: $text-color;
  }

  &.error &__link {
    color: $error-color;
  }
}
</style>
