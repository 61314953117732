<template>
  <div class="phone-field-with-constraint">
    <div class="phone-field-with-constraint__label label-title">
      {{ $t('dashboard.formulation.phoneNumber') }}
    </div>
    <phone-edit-field
      class="phone-field-with-constraint__phone-field"
      :iso-code="isoCode"
      :phone="phoneNumber"
      @update-iso-code="updateIsoCode"
      @update-phone-number="updatePhoneNumber"
    />
    <dashboard-constraint
      :value="constraint"
      :constraint-text="constraintText"
      :hint-text="constraintHint"
      :disabled="isConstraintDisabled"
      @input="updateConstraint"
    />
  </div>
</template>

<script>
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

export default {
  name: 'PhoneFieldWithConstraint',
  components: { DashboardConstraint, PhoneEditField },
  props: {
    isoCode: {
      type: String,
      required: true
    },
    phoneNumber: {
      type: String,
      required: true
    },
    constraint: {
      type: Boolean,
      default: false
    },
    constraintText: {
      type: String,
      default: ''
    },
    constraintHint: {
      type: String,
      default: ''
    },
    isConstraintDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateIsoCode(isoCode) {
      this.$emit('update-iso-code', isoCode);
    },
    updatePhoneNumber(phoneNumber) {
      this.$emit('update-phone-number', phoneNumber);
    },
    updateConstraint(constraint) {
      this.$emit('update-constraint', constraint);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/text';
</style>
