<template>
  <modal
    name="code-verification-success-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <div class="modal__content-container">
          <div class="flex-column flex--center--center text-align-center modal__text-container">
            <span class="mb30 pre-line">{{ mainText }}</span>
          </div>
        </div>
        <div class="flex-column flex--center--center">
          <div class=" rounded-button-white" @click="okClickHandler">
            {{ $t('dashboard.button.ok') }}
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'CodeVerificationSuccessModal',
  props: {
    mainText: {
      type: String,
      required: true
    }
  },
  methods: {
    okClickHandler() {
      this.$emit('ok-click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/text';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 50px 40px 30px;
  }

  &__content-container {
    margin-bottom: 25px;
  }

  &__text-container {
    margin-bottom: 20px;

    font-size: 13px;
    line-height: 150%;

    color: $active-title-color;
  }
}
</style>
